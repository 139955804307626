/* stylelint-disable-next-line scss/dollar-variable-pattern */
$minWidthForPc: 1200px;

.commLayout {
  min-width: $minWidthForPc;
  padding: 0 48px;
}

.commContent {
  max-width: 1624px;
  margin: 0 auto;
}

.headerLayout {
  min-width: $minWidthForPc;
}

.footerLayout {
  min-width: $minWidthForPc;
}
